import React, { useState, useRef, useEffect } from 'react';
import HoverIcon from "../../utils/HoverIcon";
import { useNavigate } from 'react-router-dom';
import axiosInstance from "../../utils/axiosInstance";
import { useAuth } from '../context/AuthContext/AuthContext';
import { useVariable } from '../context/VariableContext/VariableContext';

import searchBlack from '../../asset/search_black.svg';
import searchWhite from '../../asset/search_white.svg';
import sendBlack from '../../asset/comments/send_black.svg';
import sendWhite from '../../asset/comments/send_white.svg';
import pencilBlack from '../../asset/header/pencil_black.svg';
import pencilWhite from '../../asset/header/pencil_white.svg';
import pencilBlue from '../../asset/header/pencil_blue.svg';
import tieBlack from '../../asset/header/tie_black.svg';
import tieWhite from '../../asset/header/tie_white.svg';
import tieBlue from '../../asset/header/tie_blue.svg';

import gasBlack from '../../asset/header/gas_black.svg';
import gasWhite from '../../asset/header/gas_white.svg';
import gasYellow from '../../asset/header/gas_yellow.svg';

import { useMixin } from '../context/MixinContext/useMixin';

function MobileFooter() {

    const navigate = useNavigate();

    const { showPersona, setShowPersona } = useMixin();
    const { isAuthenticated, userDetails } = useAuth();
    const { state, updateState } = useVariable();

    const buyVotesRef = useRef(null);
    const editRef = useRef(null);

    const [showBuyVotes, setShowBuyVotes] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [selectedOption, setSelectedOption] = useState([]);
    const [personaDetails, setPersonaDetails] = useState([]);
    const [postValues, setPostValues] = useState({
        postName: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
        postUrl: 'https://www.lipsum.com/',
    });

    const toggleShowPersonaPopup = () => {
        // setShowPersona(!showPersona);
        navigate('in_the_news');
    };
    const toggleBuyVotesPopup = () => {
        setShowBuyVotes(!showBuyVotes);
    };
    const toggleEditPopup = () => {
        setShowEdit(!showEdit);
        getPersonaDetails();
    };

    const handleChange = (event) => {
        const selectedValue = event.target.value;
        const selectedObject = personaDetails.find((option) => option.persona_unique_id == selectedValue);
        setSelectedOption(selectedObject);
    };

    const handlePostInput = (event) => {
        const { name, value } = event.target;
        setPostValues({
            ...postValues,
            [name]: value
        });
    };

    const getPersonaDetails = async () => {
        let prmData = {
            personaUniqueId: 0,
        };
        try {
            const response = await axiosInstance.post('common/personaSelect', prmData);
            setPersonaDetails(response.data);
        } catch (error) {
            console.error('There was an error making the request!', error);
        }
    };

    const addPostDetails = async () => {
        let prmData = {
            typeOfInsertTagUniqueId: state.postInsert,
            personaUniqueId: selectedOption.persona_unique_id,
            postImage: selectedOption.persona_image,
            postName: postValues.postName,
            postUrl: postValues.postUrl,
        };
        try {
            const response = await axiosInstance.post('userAction/userActionInsert', prmData);
            alert("Successfully added");
            setPostValues({
                personaUniqueId: '',
                postName: '',
            });
        } catch (error) {
            console.error('There was an error making the request!', error);
        }
    };

    const handleClickOutside = (event) => {
        if (buyVotesRef.current && !buyVotesRef.current.contains(event.target)) {
            setShowBuyVotes(false);
        }
        if (editRef.current && !editRef.current.contains(event.target)) {
            setShowEdit(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className='flex sm:hidden fixed bottom-0 w-full justify-between bg-white dark:bg-black'>
            <div className='cursor-pointer' onClick={toggleShowPersonaPopup}>
                <HoverIcon
                    defaultIconBlack={tieBlack}
                    defaultIconWhite={tieWhite}
                    activeIcon={tieBlue}
                    altText=""
                />
            </div>
            <div className=''>
                <div className='cursor-pointer flex' onClick={toggleBuyVotesPopup}>
                    <img src={gasBlack} alt="" className="lightMode" />
                    <img src={gasWhite} alt="" className="darkMode" />
                </div>
            </div>
            <div className='cursor-pointer' onClick={toggleEditPopup}>
                <HoverIcon
                    defaultIconBlack={pencilBlack}
                    defaultIconWhite={pencilWhite}
                    activeIcon={pencilBlue}
                    altText=""
                />
            </div>

            {showBuyVotes &&
                <div ref={buyVotesRef} className="absolute right-0 bottom-24 w-full flex justify-between items-center p-[10px_15px_10px_5px] bg-[#E5E5EF] dark:bg-[#000] dark:border">
                    <h4 className="font-700">87 Votes remain</h4>
                    <button className="btnBlue w-[73px]">Buy more</button>
                </div>}

            {showEdit &&
                <div ref={editRef} className="contactCard absolute right-0 z-10 w-full bottom-[42px]">
                    <div className="relative">
                        <input type="text" className="inputField !pl-[25px] w-full" placeholder="Whom?" />
                        <div className="absolute top-[4px] left-[5px]">
                            <img src={searchWhite} alt="" className="darkMode" />
                            <img src={searchBlack} alt="" className="lightMode" />
                        </div>
                    </div>
                    <select
                        id="persona_unique_id"
                        name='persona_unique_id'
                        value={selectedOption}
                        onChange={handleChange}
                    // className="block w-full p-2 border border-gray-300 rounded-lg"
                    >
                        <option value="" disabled>
                            Select Persona
                        </option>
                        {personaDetails.map((option) => (
                            <option key={option.persona_unique_id} value={option.persona_unique_id}>
                                {option.persona_name}
                            </option>
                        ))}
                    </select>
                    <input type="text" className="inputField" placeholder="Post title max 250 chars" name="postName" value={postValues.postName} onChange={handlePostInput} />
                    <input type="text" className="inputField" placeholder="URL" name="postUrl" value={postValues.postUrl} onChange={handlePostInput} />

                    <div className="flex justify-between items-center" onClick={addPostDetails}>
                        <h5 className="font-300"></h5>
                        <img src={sendWhite} alt="" className="darkMode" />
                        <img src={sendBlack} alt="" className="lightMode" />
                    </div>
                </div>}
        </div>
    );
}

export default MobileFooter;
