import React, { useState, useEffect, useContext } from 'react';
import Post from '../components/Post.js';
import { usePost } from '../components/context/PersonaPostContext/useGlobal';
import { GlobalContext } from '../components/context/PersonaPostContext/GlobalContext';

function FilterPost() {

  const { postDetails, loading: loadingPost, error: errorPost } = usePost();
  const { setPostId } = useContext(GlobalContext);

  useEffect(() => {
    setPostId(0);
  }, [setPostId]);

  return (
    <div className="md:layout p-[4px]">
      <div className='flex flex-wrap justify-center items-start min-h-screen gap-2 lg:gap-10'>
        <div className='w-full md:w-1/2 flex flex-col gap-1 lg:gap-[10px]'>
          {postDetails.length > 0 ? (
            postDetails.map((post, index) => (
              <Post key={index} post={post} />
            ))
          ) : (
            <div>No posts available</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default FilterPost;
