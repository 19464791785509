import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import PersonaDetails from './pages/PersonaDetails';
import PostDetails from './pages/PostDetails';
import Add from './pages/Add';
import { GlobalProvider } from './components/context/PersonaPostContext/GlobalContext';
import { MixinProvider } from './components/context/MixinContext/MixinContext';
import News from './pages/News';
import Bunch from './pages/Bunch';
import FilterPost from './pages/FilterPost';

const App = () => (
  <GlobalProvider>
    <MixinProvider>
        <Router>
          <Header />
          <main className="mainBody">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/post_details/:postId" element={<PostDetails />} />
              <Route path="/persona_details/:personaId" element={<PersonaDetails />} />
              <Route path="/add" element={<Add />} />
              <Route path="/in_the_news" element={<News />} />
              <Route path="/my_bunch" element={<Bunch />} />
              <Route path="/filtered_post" element={<FilterPost />} />
            </Routes>
          </main>
          <Footer />
        </Router>
    </MixinProvider>
  </GlobalProvider>
);

export default App;
