import React from "react";
import { useSwipeable } from "react-swipeable";
import '../styles/swipe.css';

function SwipeableItem({ onShare, onDelete }) {
  const handlers = useSwipeable({
    onSwipedLeft: () => setAction("left"),
    onSwipedRight: () => setAction("right"),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  const [action, setAction] = React.useState(null);

  const handleShare = () => {
    onShare();
    setAction(null); // Reset action after sharing
  };

  const handleDelete = () => {
    onDelete();
    setAction(null); // Reset action after deleting
  };

  return (
    <div {...handlers} className="swipeable-item">
      <div className={`content ${action ? "shift" : ""}`}>
        <p>Swipe me!</p>
      </div>
      {action === "left" && (
        <div className="action-buttons">
          <button onClick={handleShare} className="share-btn">Share</button>
          <button onClick={handleDelete} className="delete-btn">Delete</button>
        </div>
      )}
    </div>
  );
}

export default SwipeableItem;
