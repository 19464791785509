import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_URL, // Set your base URL here
    //timeout: 10000, // Set a timeout if needed
    headers: {
        'Content-Type': 'application/json',
        // Add any other custom headers if needed
    },
});

// Add a request interceptor to include Authorization header
axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token'); // Retrieve the token from local storage or a secure place
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
    (response) => {
        return response; // Pass through valid responses
    },
    async (error) => {
        if (error.response?.status === 401) {
            // Handle token expiration or unauthorized access
            console.error('Unauthorized: Token might have expired.');
            // Redirect to login or refresh token logic here
        }
        return Promise.reject(error);
    }
);


export default axiosInstance;
