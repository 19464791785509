import React, { useState } from 'react';
import { useAuth } from '../components/context/AuthContext/AuthContext';


const HoverIcon = ({ defaultIconBlack, defaultIconWhite, activeIcon, altText, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);
  const { isAuthenticated } = useAuth();

  return (
    <div
      className=""
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
    >
      <img
        src={isHovered && isAuthenticated ? activeIcon : defaultIconBlack}
        alt={altText}
        className="cursor-pointer lightMode"
      />
      <img
        src={isHovered ? activeIcon : defaultIconWhite}
        alt={altText}
        className="cursor-pointer darkMode"
      />
    </div>
  );
};

export default HoverIcon;
