import React, { useState } from 'react';
import HoverIcon from "../../utils/HoverIcon";
import { useAuth } from '../context/AuthContext/AuthContext';
import { useNavigate } from 'react-router-dom';
import axiosInstance from "../../utils/axiosInstance";

import bellBlack from '../../asset/header/bell_black.svg';
import bellWhite from '../../asset/header/bell_white.svg';
import bellActiveBlack from '../../asset/header/bell_active_black.svg';
import bellActiveWhite from '../../asset/header/bell_active_white.svg';
import sendBlack from '../../asset/comments/send_black.svg';
import sendWhite from '../../asset/comments/send_white.svg';
import searchBlack from '../../asset/search_black.svg';
import searchWhite from '../../asset/search_white.svg';
import tieBlack from '../../asset/header/tie_black.svg';
import tieWhite from '../../asset/header/tie_white.svg';
import tieBlue from '../../asset/header/tie_blue.svg';

import gasBlack from '../../asset/header/gas_black.svg';
import gasWhite from '../../asset/header/gas_white.svg';
import gasYellow from '../../asset/header/gas_yellow.svg';

import pencilBlack from '../../asset/header/pencil_black.svg';
import pencilWhite from '../../asset/header/pencil_white.svg';
import pencilBlue from '../../asset/header/pencil_blue.svg';

import dp from '../../asset/header/dp.png';
import greenDot from '../../asset/green_dot.svg';

import settingsBlack from '../../asset/menu/settings_black.svg';
import settingsWhite from '../../asset/menu/settings_white.svg';
import profileBlack from '../../asset/menu/profile_black.svg';
import profileWhite from '../../asset/menu/profile_white.svg';
import logoutBlack from '../../asset/menu/logout_black.svg';
import logoutWhite from '../../asset/menu/logout_white.svg';
import MyBunch from './MyBunch';
import { useMixin } from '../context/MixinContext/useMixin';

function Menu({ setShowMyBunch, showMyBunch }) {
    const { isAuthenticated, userDetails, logout } = useAuth();
    const navigate = useNavigate();

    const [password, setPassword] = useState('12345678');

    const [showNotification, setShowNotification] = useState(false);
    const [showBuyVotes, setShowBuyVotes] = useState(false);
    const [showProfile, setShowProfile] = useState(false);
    const [showUserMenu, setShowUserMenu] = useState(false);
    const [showChangePassword, setShowChangePassword] = useState(false);
    const { showPersona, setShowPersona } = useMixin();

    const toggleNotificationPopup = () => {
        setShowNotification(!showNotification);
    };

    const toggleBuyVotesPopup = () => {
        setShowBuyVotes(!showBuyVotes);
    };

    const toggleProfilePopup = () => {
        setShowProfile(!showProfile);
    };

    const toggleMyBunchPopup = () => {
        setShowMyBunch(!showMyBunch);
        setShowPersona(!showPersona);
        navigate('my_bunch');
        setShowUserMenu(false);
    };

    const toggleChangePasswordPopup = () => {
        setShowChangePassword(!showChangePassword);
        setShowUserMenu(false);
    };

    const toggleUserMenuPopup = () => {
        setShowUserMenu(!showUserMenu);
    };

    const changePasswordFn = async () => {
        let prmData = {
            companyuseruniqueid: userDetails.companyUserUniqueId,
            password: password
        };
        try {
            const response = await axiosInstance.post('CompanyUser/changePassword', prmData);
            if (response.data[0].returntype) {
                alert("Successfully Updated!");
                setShowChangePassword(false);
            }
        } catch (error) {
            console.error('There was an error making the request!', error);
        }
    };

    return (
        <div className='flex items-center gap-[5px] sm:gap-[20px]'>
            <div className='hidden sm:flex items-center gap-[20px]'>
                <input
                    className="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault" />
                <div className='cursor-pointer' onClick={toggleMyBunchPopup}>
                    <HoverIcon
                        defaultIconBlack={tieBlack}
                        defaultIconWhite={tieWhite}
                        activeIcon={tieBlue}
                        altText=""
                    />
                </div>
                <div className='relative'>
                    <div className='cursor-pointer flex' onClick={toggleBuyVotesPopup}>
                        <img src={gasBlack} alt="" className="lightMode" />
                        <img src={gasWhite} alt="" className="darkMode" />
                        {/* <img src={gasYellow} alt="" className="" /> */}
                    </div>
                    {showBuyVotes &&
                        <div className="absolute top-[42px] right-0 flex justify-between items-center w-full lg:w-[320px] p-[10px_15px_10px_5px] bg-[#E5E5EF]">
                            <h4 className="font-700">87 Votes remain</h4>
                            <button className="btnBlue w-[73px]">Buy more</button>
                        </div>}
                </div>
                <div className='relative'>
                    <div className='cursor-pointer' onClick={toggleProfilePopup}>
                        <img src={pencilBlack} alt="" className="lightMode" />
                        <img src={pencilWhite} alt="" className="darkMode" />
                    </div>
                    {showProfile &&
                        <div className="contactCard absolute top-[42px] right-0">
                            <div className="relative">
                                <input type="text" className="inputField !pl-[25px] w-full" placeholder="Whom?" />
                                <div className="absolute top-[4px] left-[5px]">
                                    <img src={searchWhite} alt="" className="darkMode" />
                                    <img src={searchBlack} alt="" className="lightMode" />
                                </div>
                            </div>
                            <input type="text" className="inputField" placeholder="Post title max 250 chars" />
                            <input type="text" className="inputField" placeholder="URL" />

                            <div className="flex justify-between items-center">
                                <h5 className="font-300"></h5>
                                <img src={sendWhite} alt="" className="darkMode" />
                                <img src={sendBlack} alt="" className="lightMode" />
                            </div>
                        </div>}
                </div>
            </div>
            <div className='md:relative'>
                <div className='cursor-pointer flex' onClick={toggleNotificationPopup}>
                    <img src={bellBlack} alt="" className="lightMode" />
                    <img src={bellWhite} alt="" className="darkMode" />
                    {/* <img src={bellActiveBlack} alt="" className="lightMode" />
                  <img src={bellActiveWhite} alt="" className="darkMode" /> */}
                </div>
                {showNotification &&
                    <div className="absolute top-[24px] md:top-[42px] right-0 flex flex-col w-full lg:w-[320px] p-[10px] gap-[10px] bg-[#E5E5EF] dark:bg-[#000] dark:border dark:border-white">
                        <div className="flex items-center gap-[10px]">
                            <div className="w-full">
                                <div className="flex justify-between">
                                    <h4 className="font-700">Reply to your comment</h4>
                                    <h4 className="font-200">30 minutes ago</h4>
                                </div>
                                <h4 className="">maybe_fredirick replied to your comment in post “Etiam fermentum felis urna</h4>
                            </div>
                            <div>
                                <img src={greenDot} alt="" />
                            </div>
                        </div>
                        <div className="flex items-center gap-[10px]">
                            <div className="w-full">
                                <div className="flex justify-between">
                                    <h4 className="font-700">New comment on your post</h4>
                                    <h4 className="font-200">3 hours ago</h4>
                                </div>
                                <h4 className="">maybe_fredirick replied to your comment in post “Etiam fermentum felis urna</h4>
                            </div>
                            <div>
                                <img src={greenDot} alt="" />
                            </div>
                        </div>
                        <div className="flex items-center gap-[10px]">
                            <div className="w-full">
                                <div className="flex justify-between">
                                    <h4 className="font-700">Politician added</h4>
                                    <h4 className="font-200">3 days ago</h4>
                                </div>
                                <h4 className="">Obama has been successfully added</h4>
                            </div>
                            <div>
                                <img src={greenDot} alt="" />
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className='md:relative'>
                <div className='sm:ml-[10px] cursor-pointer' onClick={toggleUserMenuPopup}>
                    <img src={dp} alt="" />
                </div>
                {showUserMenu &&
                    <div className='absolute top-[24px] md:top-[42px] right-0 w-full lg:w-[105px] p-[10px] flex flex-col gap-[10px] bg-[#E5E5EF] dark:bg-[#000]'>
                        <div className='flex items-center gap-2 cursor-pointer'>
                            <div>
                                <img src={settingsWhite} className="darkMode" />
                                <img src={settingsBlack} className="lightMode" />
                            </div>
                            <h3 onClick={toggleMyBunchPopup}>My Bunch</h3>
                        </div>
                        <div className='flex items-center gap-2 cursor-pointer'>
                            <div>
                                <img src={settingsWhite} className="darkMode" />
                                <img src={settingsBlack} className="lightMode" />
                            </div>
                            <h3 onClick={toggleChangePasswordPopup}>Change Password</h3>
                        </div>
                        <div className='flex items-center gap-2 cursor-pointer'>
                            <div>
                                <img src={profileWhite} className="darkMode" />
                                <img src={profileBlack} className="lightMode" />
                            </div>
                            <h3>Update Avatar</h3>
                        </div>
                        <div className='flex items-center gap-2 cursor-pointer'>
                            <div>
                                <img src={logoutWhite} className="darkMode" />
                                <img src={logoutBlack} className="lightMode" />
                            </div>
                            <h3 onClick={logout}>Logout</h3>
                        </div>
                    </div>
                }

                {showChangePassword &&
                    <div className='absolute top-[24px] md:top-[42px] right-0 w-full lg:w-[105px] p-[10px] flex flex-col gap-[10px] bg-[#E5E5EF] dark:bg-[#000]'>
                        <div className="loginCard registrationCard">
                            <label>Change Password</label>
                            <input
                                type="password"
                                className="inputField"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <div className="flex justify-between items-center">
                                <h5 className="font-300"></h5>
                                <div onClick={changePasswordFn}>
                                    <img src={sendWhite} alt="" className="darkMode" />
                                    <img src={sendBlack} alt="" className="lightMode" />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default Menu;
