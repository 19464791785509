import React, { useState } from 'react';
import axiosInstance from "../../utils/axiosInstance";
import sendBlack from '../../asset/comments/send_black.svg';
import sendWhite from '../../asset/comments/send_white.svg';
import { useAuth } from '../context/AuthContext/AuthContext';

function Login({ closePopup }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { login, isAuthenticated, setUser } = useAuth();

    const loginFn = async () => {
        let prmData = {
            email: email,
            password: password,
        };
        try {
            const response = await axiosInstance.post('user/login', prmData);
            const token = response.data.token;
            localStorage.setItem('token', token);
            const userResponse = await axiosInstance.get('user/userData');
            const userDetails = userResponse.data;
            login(token, userDetails);
            closePopup();
        } catch (error) {
            console.error('There was an error making the request!', error);
        }
    };

    return (
        <div className="loginCard registrationCard">
            <input
                type="text"
                className="inputField"
                placeholder="User ID"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <input
                type="password"
                className="inputField"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <div className="flex justify-between items-center">
                <h5 className="font-300"></h5>
                <div onClick={loginFn}>
                    <img src={sendWhite} alt="" className="darkMode" />
                    <img src={sendBlack} alt="" className="lightMode" />
                </div>
            </div>
            {isAuthenticated && <p>Logged in successfully</p>}
        </div>
    );
}

export default Login;
