import React, { useState, useEffect } from 'react';
import axiosInstance from "../../utils/axiosInstance";
import { useAuth } from '../context/AuthContext/AuthContext';

import searchBlack from '../../asset/search_black.svg';
import searchWhite from '../../asset/search_white.svg';
import sendBlack from '../../asset/comments/send_black.svg';
import sendWhite from '../../asset/comments/send_white.svg';

function MyBunch() {
    const { userDetails } = useAuth();
    const [showSearch, setShowSearch] = useState(false);
    const [addBunch, setAddBunch] = useState(false);
    const [showAddPersona, setShowAddPersona] = useState(false);
    const [noResult, setNoResult] = useState(false);
    const [file, setFile] = useState('');
    const [previewUrl, setPreviewUrl] = useState('');
    const [address, setAddress] = useState('');
    const [addressResult, setAddressResult] = useState([]);

    const [personaValues, setPersonaValues] = useState({
        personaName: 'Barack H Obama',
        personaDescription: '44th President of the united states <br> January 20, 2009 – January 20, 2017',
        personaUrl: 'https://www.lipsum.com/',
    });

    const handlePersonaInput = (event) => {
        const { name, value } = event.target;
        setPersonaValues({
            ...personaValues,
            [name]: value
        });
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        const maxSizeMB = 2; // Max size in MB
        const maxSizeBytes = maxSizeMB * 1024 * 1024; // Convert MB to bytes

        if (selectedFile) {
            if (selectedFile.size > maxSizeBytes) {
                alert(`File size exceeds ${maxSizeMB}MB. Please select a smaller file.`);
                return;
            }
            setFile(selectedFile);
            const preview = URL.createObjectURL(selectedFile);
            setPreviewUrl(preview);
        } else {
            alert("No file selected");
        }
    };

    const imageUpload = async () => {
        if (!file) {
            alert("Please select image");
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        const headers = {
            'Content-Type': 'multipart/form-data'
        };

        try {
            const response = await axiosInstance.post('fileupload/upload', formData, { headers });
            if (response.status === 200) {
                let data = response.data;
                return data.fileName;
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const fetchPersonaApi = () => {
        const apiUrl = "https://www.googleapis.com/civicinfo/v2/representatives";
        const queryParams = {
            key: "AIzaSyC08m3M5ZzE8T4YVCInNUiZOsv80QKJsF0",
            address: address,
        };
        const queryParameters = Object.entries(queryParams)
            .map(
                ([key, value]) =>
                    `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
            )
            .join("&");
        const fullUrl = `${apiUrl}?${queryParameters}`;

        axiosInstance.get(fullUrl)
            .then((res) => {
                setAddressResult(res.data.officials);
                setNoResult(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setAddressResult([]);
                setNoResult(true);
            });
    };

    const addPersona = async (data) => {
        let prmData = {
            typeOfInsertTagUniqueId: 22, //Persona Insert
            personaName: data.name,
            personaDescription: data.party +  'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout',
            personaUrl: data.urls[1],
            personaImage: 'obama.jpg'
        };
        try {
            const response = await axiosInstance.post('userAction/userActionInsert', prmData);
        } catch (error) {
            console.error('There was an error making the request!', error);
        }
    };

    const addPersonaDetails = async () => {
        const uploadedImageName = await imageUpload();

        if (uploadedImageName) {
            let prmData = {
                typeOfInsertTagUniqueId: 22,
                PersonaName: personaValues.personaName,
                PersonaDescription: personaValues.personaDescription,
                PersonaUrl: personaValues.personaUrl,
                PersonaImage: uploadedImageName,
            };
            try {
                const response = await axiosInstance.post('userAction/userActionInsert', prmData);
                alert("Successfully added");
                document.getElementById('fileInput').value = '';
                setFile('');
                setPreviewUrl('');
                setPersonaValues({
                    personaName: '',
                    personaDescription: '',
                    personaUrl: '',
                });
            } catch (error) {
                console.error('There was an error making the request!', error);
            }
        }
    };

    const toggleSearchPopup = () => {
        setShowSearch(!showSearch);
        setAddBunch(!addBunch);
    };

    const togglePersonaForm = () => {
        setShowAddPersona(!showAddPersona);
        setShowSearch(false);
    };

    const getActiveState = () => {
        if (addBunch) {
            return 'active';
        }
        else {
            return '';
        }
    };

    useEffect(() => {
        if (address) {
            fetchPersonaApi();
        }
    }, [address]);

    return (
        <div className="rounded-[5px] bg-[#E5E5EF] dark:bg-[#FFFFFF1A]">
            <div className='flex items-center gap-[10px]'>
                <div className='p-[0px_10px]'>
                    <h1>My Bunch</h1>
                </div>
                <button className={`addButton ${getActiveState()} w-[48px] h-[22px]`} onClick={toggleSearchPopup}>Add</button>
            </div>

            {showSearch &&
                <div className='absolute top-[28px] left-[0px] right-[0px] m-auto w-[96%] bg-white p-[20px_5px]'>
                    <div className='relative'>
                        <input type="text" className="whomField" placeholder="Whom?" onChange={(e) => setAddress(e.target.value)} />
                        <div className="absolute top-[4px] left-[6px]">
                            <img src={searchWhite} alt="" className="darkMode" />
                            <img src={searchBlack} alt="" className="lightMode" />
                        </div>
                        {addressResult.length > 0 &&
                            <div className="w-full bg-white h-[150px] overflow-y-scroll">
                                <table className="">
                                    <thead className="">
                                        <tr>
                                            <th scope="col" className="text-[12px] text-left">Representative</th>
                                            <th scope="col" className="text-[12px] text-left">URLs</th>
                                            <th scope="col" className="text-[12px] text-left">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {addressResult.map((item, index) => (
                                            <tr
                                                key={index}
                                                className=""
                                            >
                                                <td className="text-[12px]">{item.name}</td>
                                                <td className="text-[12px]">
                                                    {item.urls.map((url, urlIndex) => (
                                                        <div key={urlIndex} className="">
                                                            <a href={url} target="_blank" rel="noopener noreferrer">
                                                                {url}
                                                            </a>
                                                        </div>
                                                    ))}
                                                </td>
                                                <td className="">
                                                    <button className="" type="button" onClick={() => addPersona(item)}>
                                                        Add
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        }
                        {noResult &&
                            <div>
                                <h5>No results. Add new</h5>
                                <button className={`addButton h-[22px] p-[0px_15px] absolute top-[3px] right-[6px]`} onClick={togglePersonaForm}>Add new</button>
                            </div>
                        }
                    </div>
                </div>
            }
            {showAddPersona &&
                <div className='absolute top-[28px] left-[0px] right-[0px] m-auto w-[96%] bg-white'>
                    <div className="loginCard registrationCard">
                        <div>
                            <input type="file" accept="image/jpeg, image/png" id="fileInput" onChange={handleFileChange} />
                            {previewUrl && <img src={previewUrl} alt="Image Preview" className="w-[100px]" />}
                        </div>
                        <input type="text" className="inputField" placeholder="Name" name="personaName" value={personaValues.personaName} onChange={handlePersonaInput} />
                        <input type="text" className="inputField" placeholder="Description" name="personaDescription" value={personaValues.personaDescription} onChange={handlePersonaInput} />
                        <input type="text" className="inputField" placeholder="Verification URL" name="personaUrl" value={personaValues.personaUrl} onChange={handlePersonaInput} />
                        <h4>Note: Allow us 2 days to review.</h4>

                        <div className="flex justify-between items-center" onClick={addPersonaDetails}>
                            <h5 className="font-300"></h5>
                            <img src={sendWhite} alt="" className="darkMode" />
                            <img src={sendBlack} alt="" className="lightMode" />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default MyBunch;
