import React, { useState, useEffect } from 'react';
import axiosInstance from "../../utils/axiosInstance";
import { useAuth } from '../context/AuthContext/AuthContext';
import { useMixin } from '../context/MixinContext/useMixin';

import replyBlack from '../../asset/comments/reply_black.svg';
import replyWhite from '../../asset/comments/reply_white.svg';
import replyIconActive from '../../asset/comments/replyIconActive.svg';

import burnBlack from '../../asset/comments/burn_black.svg';
import burnWhite from '../../asset/comments/burn_white.svg';
import burnIconActive from '../../asset/comments/burnIconActive.svg';

import upArrowBlack from '../../asset/comments/up_arrow_black.svg';
import upArrowWhite from '../../asset/comments/up_arrow_white.svg';
import upArrowIconActive from '../../asset/comments/upArrowIconActive.svg';

import downArrowBlack from '../../asset/comments/down_arrow_black.svg';
import downArrowWhite from '../../asset/comments/down_arrow_white.svg';
import downArrowIconActive from '../../asset/comments/downArrowIconActive.svg';

import moreBlack from '../../asset/comments/more_black.svg';
import moreWhite from '../../asset/comments/more_white.svg';
import moreIconActive from '../../asset/comments/moreIconActive.svg';

import profilePic from '../../asset/profilePic.png';

import ReplyList from './ReplyList';
import ReplyForm from './ReplyForm';
import HoverIcon from "../../utils/HoverIcon";

import flag from '../../asset/flag.svg';
import shareBlack from '../../asset/share_black.svg';

function CommentSection({ postId, sendData }) {
    const { isAuthenticated, userDetails } = useAuth();
    const [comments, setComments] = useState([]);
    const [ID, setID] = useState(false);
    const [showReplyForm, setShowReplyForm] = useState(false);
    const [showReplyList, setShowReplyList] = useState(false);
    const [showFlag, setShowFlag] = useState(false);
    const [awardStatus, setAwardStatus] = useState(false);
    const [call, setCall] = useState(false);
    const { showLoginPopup, setShowLoginPopup, setPopupHeading } = useMixin();


    const handleShowReplyForm = (commentId) => {
        if (isAuthenticated) {
            setID(commentId);
            setShowReplyForm(prevState => !prevState);
            setShowReplyList(false);
        }
        else {
            toggleLoginPopup('Login to reply');
        }
    };

    const handleShowReplyList = (commentId) => {
        setID(commentId);
        setShowReplyList(prevState => !prevState);
        setShowReplyForm(false);
    };

    const addAward = (post_comment_unique_id, awardStatus) => {
        if (isAuthenticated) {
            let prmData = {
                typeOfInsertTagUniqueId: 34,
                postCommentAwardStatus: !awardStatus,
                postUniqueId: parseInt(postId),
                PostCommentUniqueId: post_comment_unique_id
            };
            try {
                const response = axiosInstance.post('userAction/userActionInsert', prmData);
                // setCall(prevState => !prevState);
            } catch (error) {
                console.error('There was an error making the request!', error);
            }
        }
        else {
            toggleLoginPopup('Login to award Burns');
        }
    };

    const addLikes = async (post_comment_unique_id, islike, voteType) => {
        if (isAuthenticated) {
            let prmData = {
                typeOfInsertTagUniqueId: 32,
                PostCommentLikeTagUniqueId: islike,
                postUniqueId: parseInt(postId),
                PostCommentUniqueId: post_comment_unique_id
            };
            try {
                const response = await axiosInstance.post('userAction/userActionInsert', prmData);
                // setCall(prevState => !prevState);
            } catch (error) {
                console.error('There was an error making the request!', error);
            }
        }
        else {
            if (voteType == 'upVote') {
                toggleLoginPopup('Login to UpVote');
            }
            else {
                toggleLoginPopup('Login to DownVote');
            }
        }
    };

    const toggleFlag = (commentId) => {
        if (isAuthenticated) {
            setID(commentId);
            setShowFlag(prevState => !prevState);
        }
        else {
            toggleLoginPopup('Login for more');
        }
    };

    const addFlag = (post_comment_unique_id) => {
        setShowFlag(prevState => !prevState);
        let prmData = {
            typeOfInsertTagUniqueId: 30,
            PostCommentFlagTagUniqueId: parseInt(selectedOption),
            postUniqueId: parseInt(postId),
            PostCommentUniqueId: post_comment_unique_id
        };
        try {
            const response = axiosInstance.post('userAction/userActionInsert', prmData);
            // setCall(prevState => !prevState);
        } catch (error) {
            console.error('There was an error making the request!', error);
        }
    };

    const getCommentsList = async () => {
        let prmData = {
            postuniqueid: parseInt(postId)
        };
        try {
            const response = await axiosInstance.post('common/commentSelect', prmData);
            let data = response.data;
            const mergedArray = data.concat(sendData);
            setComments(mergedArray);
        } catch (error) {
            console.error('There was an error making the request!', error);
        }
    };

    const [selectedOption, setSelectedOption] = useState("");

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };

    useEffect(() => {
        getCommentsList();
    }, [postId, call]);

    const toggleLoginPopup = (message) => {
        setShowLoginPopup(!showLoginPopup);
        setPopupHeading(message);
    };

    return (
        <div className='mx-[5px]'>
            {comments.map((eachData, index) => (
                <div className='flex flex-col gap-[5px]' key={index}>
                    <div className='flex items-center gap-[5px]'>
                        {eachData.user_image ?
                            <img src={`${process.env.REACT_APP_IMAGE_URL}${eachData.user_image}`} alt="" className='w-[15px] h-[15px]' />
                            :
                            <img src={profilePic} alt="" className='w-[15px] h-[15px]' />
                        }
                        <h5 className='font-500'>{eachData.user_name}</h5>
                        <h6 className='font-100'>{eachData.time_ago}</h6>
                    </div>
                    <div>
                        <h4 className='font-400 ml-[20px]'>{eachData.comments}</h4>
                    </div>
                    <div className='flex items-center gap-4 ml-[20px]'>
                        <HoverIcon
                            defaultIconBlack={replyBlack}
                            defaultIconWhite={replyWhite}
                            activeIcon={replyIconActive}
                            altText="Reply"
                            onClick={() => handleShowReplyForm(eachData.post_comment_unique_id)}
                        />
                        {eachData.no_of_replies > 0 &&
                            <div className='text-[#0000001A] dark:text-[#FFFFFF1A] hover:text-[#4169E1] font-bold cursor-pointer' onClick={() => handleShowReplyList(eachData.post_comment_unique_id)}>{eachData.no_of_replies}R</div>
                        }
                        {eachData.award_status ?
                            <HoverIcon
                                defaultIconBlack={burnIconActive}
                                defaultIconWhite={burnIconActive}
                                activeIcon={burnIconActive}
                                altText="Burn"
                                onClick={() => addAward(eachData.post_comment_unique_id, eachData.award_status)}
                            />
                            :
                            <HoverIcon
                                defaultIconBlack={burnBlack}
                                defaultIconWhite={burnWhite}
                                activeIcon={burnIconActive}
                                altText="Burn"
                                onClick={() => addAward(eachData.post_comment_unique_id, eachData.award_status)}
                            />
                        }
                        <HoverIcon
                            defaultIconBlack={upArrowBlack}
                            defaultIconWhite={upArrowWhite}
                            activeIcon={upArrowIconActive}
                            altText=""
                            onClick={() => addLikes(eachData.post_comment_unique_id, 20, 'upVote')}
                        />
                        {eachData.no_of_likes
                            ?
                            <div className='text-[#0000001A] dark:text-[#FFFFFF1A] hover:text-[#4169E1] font-bold cursor-pointer'>{eachData.no_of_likes}</div>
                            :
                            <div className='text-[#0000001A] dark:text-[#FFFFFF1A] hover:text-[#4169E1] font-bold cursor-pointer'>0</div>
                        }
                        <HoverIcon
                            defaultIconBlack={downArrowBlack}
                            defaultIconWhite={downArrowWhite}
                            activeIcon={downArrowIconActive}
                            altText=""
                            onClick={() => addLikes(eachData.post_comment_unique_id, 21, 'downVote')}
                        />
                        <HoverIcon
                            defaultIconBlack={moreBlack}
                            defaultIconWhite={moreWhite}
                            activeIcon={moreIconActive}
                            altText=""
                            onClick={() => toggleFlag(eachData.post_comment_unique_id)}
                        />
                    </div>

                    {showReplyForm && (ID === eachData.post_comment_unique_id) && (
                        <ReplyForm data={eachData} type="reply" getCommentsList={getCommentsList} />
                    )}

                    {showReplyList && (ID === eachData.post_comment_unique_id) && (
                        <ReplyList data={eachData} type="reply" />
                    )}

                    {isAuthenticated && showFlag && (ID === eachData.post_comment_unique_id) && (
                        <div>
                            <div className='flex gap-3 ml-[20px]'>
                                <img src={flag} alt="" />
                                <div>
                                    <select
                                        id="options"
                                        value={selectedOption}
                                        onChange={handleChange}
                                        className="w-[100%] p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-700"
                                    >
                                        <option value="" disabled>
                                            Select an option
                                        </option>
                                        <option value="16">Hate</option>
                                        <option value="18">Racist</option>
                                        <option value="19">Profanity</option>
                                    </select>
                                </div>
                                <div className='btnFlag' onClick={() => addFlag(eachData.post_comment_unique_id)}>Flag</div>
                            </div>
                            {/* <img src={shareBlack} alt="" /> */}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}

export default CommentSection;
