import React, { useState, useEffect } from 'react';
import axiosInstance from "../../utils/axiosInstance";
import { useAuth } from '../context/AuthContext/AuthContext';

import replyBlack from '../../asset/comments/reply_black.svg';
import replyWhite from '../../asset/comments/reply_white.svg';
import replyIconActive from '../../asset/comments/replyIconActive.svg';

import burnBlack from '../../asset/comments/burn_black.svg';
import burnWhite from '../../asset/comments/burn_white.svg';
import burnIconActive from '../../asset/comments/burnIconActive.svg';

import upArrowBlack from '../../asset/comments/up_arrow_black.svg';
import upArrowWhite from '../../asset/comments/up_arrow_white.svg';
import upArrowIconActive from '../../asset/comments/upArrowIconActive.svg';

import downArrowBlack from '../../asset/comments/down_arrow_black.svg';
import downArrowWhite from '../../asset/comments/down_arrow_white.svg';
import downArrowIconActive from '../../asset/comments/downArrowIconActive.svg';

import moreBlack from '../../asset/comments/more_black.svg';
import moreWhite from '../../asset/comments/more_white.svg';
import moreIconActive from '../../asset/comments/moreIconActive.svg';

import profilePic from '../../asset/profilePic.png';
// import ReplyList from './ReplyList';
import ReplyForm from './ReplyForm';
import HoverIcon from "../../utils/HoverIcon";

function ReplyList({ data, type }) {
    const { isAuthenticated, userDetails } = useAuth();
    const [replyData, setReply] = useState([]);

    const [ID, setID] = useState(false);
    const [showReplyForm, setShowReplyForm] = useState(false);
    const [showReplyList, setShowReplyList] = useState(false);

    const [call, setCall] = useState(false);

    const handleShowReplyForm = (commentId) => {
        setID(commentId);
        setShowReplyForm(prevState => !prevState);
        setShowReplyList(false);
    };

    const handleShowReplyList = (commentId) => {
        setID(commentId);
        setShowReplyList(prevState => !prevState);
        setShowReplyForm(false);
    };

    const getReplyList = async () => {
        let prmData = {
            postuniqueid: data.post_unique_id,
            commentuniqueid: data.post_comment_unique_id,
        };
        try {
            const response = await axiosInstance.post('common/replySelect', prmData);
            setReply(response.data);
        } catch (error) {
            console.error('There was an error making the request!', error);
        }

    };

    const getNestedReplyList = async () => {
        let prmData = {
            postuniqueid: data.postuniqueid,
            parentpostcommentuniqueid: data.post_comment_unique_id,
        };
        try {
            const response = await axiosInstance.post('reply/select', prmData);
            setReply(response.data);

        } catch (error) {
            console.error(error);
        }

    };

    useEffect(() => {
        if (type === 'reply') {
            getReplyList();
        } else {
            getReplyList();
        }
    }, [data, type, call]);

    const addLikes = async (post_comment_unique_id, islike) => {
        let prmData = {
            userid: userDetails.companyUserUniqueId,
            postid: data.postuniqueid,
            commentid: post_comment_unique_id,
            islike: islike
        };
        try {
            const response = await axiosInstance.post('likes/insert', prmData);
            setCall(prevState => !prevState);
        } catch (error) {
            console.error('There was an error making the request!', error);
        }
    };

    return (
        <div className=''>
            {replyData.map((eachData, index) => (
                <div className='replySection ml-4' key={index}>
                    <div className='flex items-center gap-1'>
                        <img src={profilePic} alt="" className='w-[15px] h-[15px]' />
                        <h5 className='font-500'>{eachData.username}</h5>
                        <h6 className='font-100'>{eachData.timeago}</h6>
                    </div>
                    <div>
                        <h4 className='font-400 ml-[19px]'>{eachData.comments}</h4>
                    </div>
                    <div className='flex items-center gap-4 ml-[20px]'>
                        <HoverIcon
                            defaultIconBlack={replyBlack}
                            defaultIconWhite={replyWhite}
                            activeIcon={replyIconActive}
                            altText="Reply"
                            onClick={() => handleShowReplyForm(eachData.post_comment_unique_id)}
                        />
                        {eachData.no_of_replies > 0 &&
                            <div className='text-[#0000001A] dark:text-[#FFFFFF1A] hover:text-[#4169E1] font-bold cursor-pointer' onClick={() => handleShowReplyList(eachData.post_comment_unique_id)}> {eachData.no_of_replies}R </div>
                        }
                        <HoverIcon
                            defaultIconBlack={burnBlack}
                            defaultIconWhite={burnWhite}
                            activeIcon={burnIconActive}
                            altText="Burn"
                        />
                        <HoverIcon
                            defaultIconBlack={upArrowBlack}
                            defaultIconWhite={upArrowWhite}
                            activeIcon={upArrowIconActive}
                            altText=""
                            onClick={() => addLikes(eachData.post_comment_unique_id, true)}
                        />
                        <div className='text-[#0000001A] dark:text-[#FFFFFF1A] hover:text-[#4169E1] font-bold cursor-pointer'>{eachData.nooflikes}</div>
                        <HoverIcon
                            defaultIconBlack={downArrowBlack}
                            defaultIconWhite={downArrowWhite}
                            activeIcon={downArrowIconActive}
                            altText=""
                            onClick={() => addLikes(eachData.post_comment_unique_id, false)}
                        />
                        <HoverIcon
                            defaultIconBlack={moreBlack}
                            defaultIconWhite={moreWhite}
                            activeIcon={moreIconActive}
                            altText=""
                        />
                    </div>
                    {showReplyForm && (ID === eachData.post_comment_unique_id) && (
                        <ReplyForm data={eachData} type="nestedReply" getReplyList={getReplyList} />
                    )}

                    {showReplyList && (ID === eachData.post_comment_unique_id) && (
                        <ReplyList data={eachData} type="nestedReply" getReplyList={getReplyList} />
                    )}
                </div>
            ))}

        </div>
    );
}

export default ReplyList;
