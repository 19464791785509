import React, { useState, useEffect, useContext } from 'react';
import Persona from "../components/Persona";
import { usePersona, usePost } from '../components/context/PersonaPostContext/useGlobal';
import { GlobalContext } from '../components/context/PersonaPostContext/GlobalContext';

function News() {

  const { personaDetails, loading: loadingPersona, error: errorPersona } = usePersona();
  const { setPersonaId } = useContext(GlobalContext);

  useEffect(() => {
    setPersonaId(0);
  }, [setPersonaId]);

  return (
    <div className="md:layout p-[4px]">
      <div className='flex flex-wrap justify-center items-start min-h-screen gap-2 lg:gap-10'>
        <div className='w-full md:w-1/4 flex flex-col gap-1 lg:gap-[10px]'>
          {personaDetails.length > 0 ? (
            personaDetails.map((persona, index) => (
              <Persona key={index} persona={persona} />
            ))
          ) : (
            <div>No personas available</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default News;
