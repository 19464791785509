import React, { useState, useEffect } from 'react';
import axiosInstance from "../../utils/axiosInstance";
import { useAuth } from '../context/AuthContext/AuthContext';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function AddPost() {
    const { userDetails } = useAuth();

    const [file, setFile] = useState('');
    const [previewUrl, setPreviewUrl] = useState('');
    const [personaDetails, setPersonaDetails] = useState([]);

    const [postValues, setPostValues] = useState({
        personaUniqueId: '',
        postName: '',
    });

    const handlePostInput = (event) => {
        const { name, value } = event.target;
        setPostValues({
            ...postValues,
            [name]: value
        });
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        const maxSizeMB = 2; // Max size in MB
        const maxSizeBytes = maxSizeMB * 1024 * 1024; // Convert MB to bytes

        if (selectedFile) {
            if (selectedFile.size > maxSizeBytes) {
                alert(`File size exceeds ${maxSizeMB}MB. Please select a smaller file.`);
                return;
            }
            setFile(selectedFile);
            const preview = URL.createObjectURL(selectedFile);
            setPreviewUrl(preview);
        } else {
            alert("No file selected");
        }
    };

    const imageUpload = async () => {
        if (!file) {
            alert("Please select image");
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        const headers = {
            'Content-Type': 'multipart/form-data'
        }

        try {
            const response = await axiosInstance.post('fileupload/upload', formData, { headers });
            if (response.status === 200) {
                let data = response.data;
                return data.fileName;
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const getPersonaDetails = async () => {
        let prmData = {
            personaUniqueId: 0,
        };
        try {
            const response = await axiosInstance.post('common/personaSelect', prmData);
            setPersonaDetails(response.data);
        } catch (error) {
            console.error('There was an error making the request!', error);
        }
    };

    const addPostDetails = async () => {
        if (postValues.personaUniqueId == 0) {
            alert("Please select persona");
            return;
        }
        const uploadedImageName = await imageUpload();

        if (uploadedImageName) {
            let prmData = {
                personaUniqueId: postValues.personaUniqueId,
                postName: postValues.postName,
                postImage: uploadedImageName,
                verificationLevelTagUniqueId: 1,
                logUserUniqueId: userDetails.companyUserUniqueId,
            };
            try {
                const response = await axiosInstance.post('post/insert', prmData);
                alert("Successfully added");
                toast.success("Successfully added");
                document.getElementById('fileInput').value = '';
                setFile('');
                setPreviewUrl('');
                setPostValues({
                    personaUniqueId: '',
                    postName: '',
                });
            } catch (error) {
                console.error('There was an error making the request!', error);
            }
        }
    };

    useEffect(() => {
        getPersonaDetails();
    }, []);

    return (
        <div>
            <div className='w-[40%] m-auto flex flex-col gap-2'>
                <div>Add Post</div>
                <select
                    name="personaUniqueId"
                    value={postValues.personaUniqueId}
                    onChange={handlePostInput}
                    className="block w-full p-2 border border-gray-300 rounded-lg"
                >
                    <option value="" disabled>Select Persona</option>
                    {personaDetails.map((eachData, index) => (
                        <option key={`${eachData.personauniqueid}-${index}`} value={eachData.personauniqueid}>
                            {eachData.personaname}
                        </option>
                    ))}
                </select>
                <div>
                    <input type="file" accept="image/jpeg, image/png" id="fileInput" onChange={handleFileChange} />
                    {previewUrl && <img src={previewUrl} alt="Image Preview" className="w-[100px]" />}
                </div>
                <input
                    type="text"
                    name="postName"
                    value={postValues.postName}
                    onChange={handlePostInput}
                    placeholder='Post Name'
                    className="testField" />
                <button className='btn-custom bg-blue-100' onClick={addPostDetails}>Submit</button>
            </div>
        </div>
    );
}

export default AddPost;
