import React from 'react';
import { useMixin } from '../context/MixinContext/useMixin';

import apple from '../../asset/login/apple.svg';
import facebook from '../../asset/login/facebook.svg';
import google from '../../asset/login/google.svg';
import mail from '../../asset/login/mail.svg';

function MainAuth({ showGoogle, showSignUp, showLogin }) {

    const { popupHeading, setPopupHeading } = useMixin();

    return (
        <div className='dialogClass gap-[10px]'>
            <h3 className='font-700'>
                {popupHeading}
            </h3>
            <div className='flex items-center gap-1 cursor-pointer' onClick={showGoogle}>
                <img src={google} alt="google" />
                <h3 className='font-400'>Login with Google</h3>
            </div>
            <div className='flex items-center gap-1 cursor-pointer'>
                <img src={apple} alt="apple" className='w-[20px]' />
                <h3 className='font-400'>Login with Apple</h3>
            </div>
            <div className='flex items-center gap-1 cursor-pointer'>
                <img src={facebook} alt="facebook" />
                <h3 className='font-400'>Login with Facebook</h3>
            </div>
            <div className='flex items-center gap-1 cursor-pointer' onClick={showSignUp}>
                <img src={mail} alt="mail" />
                <h3 className='font-400'>Create new</h3>
            </div>
            <div className='flex items-center gap-1 cursor-pointer' onClick={showLogin}>
                <img src={mail} alt="mail" />
                <h3 className='font-400'>Login</h3>
            </div>
        </div>
    );
}

export default MainAuth;
