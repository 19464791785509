import React, { createContext, useContext, useState } from 'react';

// Define initial state
const initialState = {
  user: null,
  personaInsert: 22,
  postInsert: 24,
  voteInsert: 26,
  commentInsert: 28,
  flagInsert: 30,
  likeInsert: 32,
  awardInsert: 34,
};

// Create Context
const VariableContext = createContext();

// GlobalStateProvider Component
export const VariableProvider = ({ children }) => {
  const [state, setState] = useState(initialState);

  // Function to update specific fields in the state
  const updateState = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <VariableContext.Provider value={{ state, updateState }}>
      {children}
    </VariableContext.Provider>
  );
};

// Custom Hook to Access Context
export const useVariable = () => useContext(VariableContext);
