import React, { useState, useEffect } from 'react';
import axiosInstance from "../../utils/axiosInstance";
import { useAuth } from '../context/AuthContext/AuthContext';
import { useVariable } from '../context/VariableContext/VariableContext';

import sendBlack from '../../asset/comments/send_black.svg';
import sendWhite from '../../asset/comments/send_white.svg';

function StepThree({ closePopup }) {
    const { login } = useAuth();
    const { state, updateState } = useVariable();
    const [showHow, setShowHow] = useState(false);
    const [address, setAddress] = useState('');
    // Mountain Loop Highway, Granite Falls, WA, USA
    const [addressResult, setAddressResult] = useState([]);

    const email = localStorage.getItem('emailStorage');
    const password = localStorage.getItem('passwordStorage');

    const showHowToggle = () => {
        setShowHow(!showHow);
    };

    const stepThreeFn = async () => {
        let prmData = {
        };
        try {
            const response = await axiosInstance.post('CompanyUser/insert', prmData);
        } catch (error) {
            console.error('There was an error making the request!', error);
        }
    };

    const fetchPersonaApi = () => {
        const apiUrl = "https://www.googleapis.com/civicinfo/v2/representatives";
        const queryParams = {
            key: "AIzaSyC08m3M5ZzE8T4YVCInNUiZOsv80QKJsF0",
            address: address,
        };
        const queryParameters = Object.entries(queryParams)
            .map(
                ([key, value]) =>
                    `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
            )
            .join("&");
        const fullUrl = `${apiUrl}?${queryParameters}`;

        axiosInstance.get(fullUrl)
            .then((res) => {
                setAddressResult(res.data.officials);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setAddressResult([]);
            });
    };

    const handleSkip = () => {
        closePopup();
        loginFn();
    }

    const loginFn = async () => {
        let prmData = {
            email: email,
            password: password,
        };
        try {
            const response = await axiosInstance.post('user/login', prmData);
            const token = response.data.token;
            localStorage.setItem('token', token);
            const userResponse = await axiosInstance.get('user/userData');
            const userDetails = userResponse.data;
            login(token, userDetails);
            closePopup();
        } catch (error) {
            console.error('There was an error making the request!', error);
        }
    };

    const addPersona = async (data) => {
        let prmData = {
            typeOfInsertTagUniqueId: state.personaInsert,
            personaName: data.name,
            personaDescription: data.party + 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout',
            personaUrl: data.urls[1],
            personaImage: 'obama.jpg'
        };
        try {
            const response = await axiosInstance.post('userActions/userActionInsert', prmData);
        } catch (error) {
            console.error('There was an error making the request!', error);
        }
    };

    useEffect(() => {
        if (address) {
            fetchPersonaApi();
        }
    }, [address]);

    return (
        <div>
            <div className="registrationCard">
                <h4 className="font-bold">Country :</h4>
                <div className="flex flex-col gap-[2px]">
                    <h4>Use address to find your representatives</h4>
                    <input
                        type="text"
                        className="inputField w-full"
                        placeholder="1000, Bank st, Richmond, VA 23218"
                        onChange={(e) => setAddress(e.target.value)}
                    />
                    {addressResult.length > 0 &&
                        <div className="w-full bg-white h-[150px] overflow-y-scroll">
                            <table className="">
                                <thead className="">
                                    <tr>
                                        <th scope="col" className="text-[12px] text-left">Representative</th>
                                        <th scope="col" className="text-[12px] text-left">URLs</th>
                                        <th scope="col" className="text-[12px] text-left">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {addressResult.map((item, index) => (
                                        <tr
                                            key={index}
                                            className=""
                                        >
                                            <td className="text-[12px]">{item.name}</td>
                                            <td className="text-[12px]">
                                                {item.urls.map((url, urlIndex) => (
                                                    <div key={urlIndex} className="">
                                                        <a href={url} target="_blank" rel="noopener noreferrer">
                                                            {url}
                                                        </a>
                                                    </div>
                                                ))}
                                            </td>
                                            <td className="">
                                                <button className="" type="button" onClick={() => addPersona(item)}>
                                                    Add
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    }
                    <h5 className="font-300 text-end">
                        Address is stored & used in API to look up representative.
                    </h5>
                    <h5 className="font-300 underline text-end cursor-pointer" onClick={showHowToggle}>How?</h5>
                </div >
                <div className="flex justify-between">
                    <h5 className="font-300 cursor-pointer" onClick={handleSkip}>Skip</h5>
                    <div onClick={stepThreeFn}>
                        <img src={sendWhite} alt="" className="darkMode" />
                        <img src={sendBlack} alt="" className="lightMode" />
                    </div>
                </div>
            </div >
            {
                showHow &&
                <div className="registrationCard">
                    <h5>
                        Address is input to Google API to fetch a list of available local,
                        state & federal representatives. It is encrypted and stored for future
                        election updates. Learn more...
                    </h5>
                </div>
            }
        </div>
    );
}

export default StepThree;
