import React, { useState, useEffect } from 'react';
import axiosInstance from "../../utils/axiosInstance";

import sendBlack from '../../asset/comments/send_black.svg';
import sendWhite from '../../asset/comments/send_white.svg';

function StepTwo({ showStepThree }) {
    let userID = localStorage.getItem('userID');

    const [showWhy, setShowWhy] = useState(false);
    const [name, setName] = useState('');

    const showWhyToggle = () => {
        setShowWhy(!showWhy);
    };

    const stepTwoFn = async () => {
        showStepThree();

        let prmData = {
            UserUniqueId: userID,
            AvatarName: name,
        };
        try {
            const response = await axiosInstance.post('user/avatar', prmData);
            showStepThree();
            alert("avatar name added successfully")
        } catch (error) {
            console.error('There was an error making the request!', error);
        }
    };

    return (
        <div>
            <div className="registrationCard">
                <h4>Registration Successful!</h4>
                <div className="flex flex-col gap-[2px]">
                    <label htmlFor="avatarName">
                        <h4>Name your avatar</h4>
                    </label>
                    <input
                        type="text"
                        id="avatarName"
                        className="inputField w-full"
                        placeholder="maybe_one_eyed_joe!"
                        onChange={(e) => setName(e.target.value)} 
                    />
                    <h5 className="font-300 text-end">We advocate anonymity.</h5>
                    <h5 className="font-300 underline text-end cursor-pointer" onClick={showWhyToggle}>Why?</h5>
                </div>
                <div className="flex justify-between items-center" onClick={stepTwoFn}>
                    <h5 className="font-300"></h5>
                    <img src={sendWhite} alt="" className="darkMode" />
                    <img src={sendBlack} alt="" className="lightMode" />
                </div>
            </div>
            {showWhy &&
                <div className="registrationCard">
                    <h5>
                        Anonymity would promote impersonal conversations, reduce ad hominem
                        attacks. Learn more...
                    </h5>
                </div>
            }
        </div>
    );
}

export default StepTwo;
