import React, { createContext, useState } from 'react';

export const MixinContext = createContext();

export const MixinProvider = ({ children }) => {
  const [showPersona, setShowPersona] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [popupHeading, setPopupHeading] = useState('');

  return (
    <MixinContext.Provider value={
      {
        showPersona, setShowPersona, showLoginPopup,
        setShowLoginPopup, popupHeading, setPopupHeading
      }
    }>
      {children}
    </MixinContext.Provider>
  );
};
