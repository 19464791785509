import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
// 
import logoBig from '../asset/header/header_big_logo.svg';
import logoSmall from '../asset/header/header_small_logo.svg';

// 
import loginBlack from '../asset/header/loginBlack.svg';
import loginWhite from '../asset/header/loginWhite.svg';

import { useAuth } from "./context/AuthContext/AuthContext";
import { useMixin } from './context/MixinContext/useMixin';
// 

import MainAuth from './auth/MainAuth';
import Login from './auth/Login';
import Signup from './auth/Signup';
import Google from './auth/Google';
import StepTwo from './auth/StepTwo';
import StepThree from './auth/StepThree';
import Drp from './Header/Drp';
import MyBunch from './Header/MyBunch';
import Menu from './Header/Menu';
import MobileFooter from './Header/MobileFooter';

function Header() {
  const { isAuthenticated } = useAuth();
  const loginRef = useRef(null);

  const [showMyBunch, setShowMyBunch] = useState(false);
  const [mainAuthPopup, setMainAuthPopup] = useState(false);
  const [signupPopup, setSignupPopup] = useState(false);
  const [googlePopup, setGooglePopup] = useState(false);
  const [loginPopup, setLoginPopup] = useState(false);
  const [stepTwoPopup, setStepTwoPopup] = useState(false);
  const [stepThreePopup, setStepThreePopup] = useState(false);
  const { showLoginPopup, setShowLoginPopup } = useMixin();

  // const toggleLoginPopup = () => {
  //   setMainAuthPopup(!mainAuthPopup);
  //   setSignupPopup(false);
  //   setGooglePopup(false);
  //   setLoginPopup(false);
  //   setStepTwoPopup(false);
  //   setStepThreePopup(false);
  // };

  const toggleLoginPopup = () => {
    setShowLoginPopup(!showLoginPopup);
    setSignupPopup(false);
    setGooglePopup(false);
    setLoginPopup(false);
    setStepTwoPopup(false);
    setStepThreePopup(false);
  };

  const closePopup = () => {
    setMainAuthPopup(false);
    setShowLoginPopup(false);
    setSignupPopup(false);
    setGooglePopup(false);
    setLoginPopup(false);
    setStepTwoPopup(false);
    setStepThreePopup(false);
  };
  const showSignUp = () => {
    setMainAuthPopup(false);
    setShowLoginPopup(false);
    setSignupPopup(true);
    setGooglePopup(false);
    setLoginPopup(false);
  };
  const showGoogle = () => {
    setMainAuthPopup(false);
    setShowLoginPopup(false);
    setSignupPopup(false);
    setGooglePopup(true);
    setLoginPopup(false);
  };
  const showLogin = () => {
    setMainAuthPopup(false);
    setShowLoginPopup(false);
    setSignupPopup(false);
    setGooglePopup(false);
    setLoginPopup(true);
  };
  const showStepTwo = () => {
    setMainAuthPopup(false);
    setShowLoginPopup(false);
    setSignupPopup(false);
    setGooglePopup(false);
    setLoginPopup(false);
    setStepTwoPopup(true);
    setStepThreePopup(false);
  };
  const showStepThree = () => {
    setMainAuthPopup(false);
    setShowLoginPopup(false);
    setSignupPopup(false);
    setGooglePopup(false);
    setLoginPopup(false);
    setStepTwoPopup(false);
    setStepThreePopup(true);
  };

  useEffect(() => {
    const closeLoginPopup = (event) => {
      if (loginRef.current && !loginRef.current.contains(event.target)) {
        // setShowLogin(false);
        setMainAuthPopup(false);
      }
    };

    document.addEventListener("mousedown", closeLoginPopup);

    return () => {
      document.removeEventListener("mousedown", closeLoginPopup);
    };
  }, []);

  return (
    <header>
      <div className='fixed w-full top-[0px] bg-[#fff] dark:bg-[#000] z-10'>
        <div className='relative flex justify-between items-center p-[0px_5px] lg:p-[10px_15px]'>
          <Link to='/'>
            <div className='flex items-center'>
              <div>
                <img src={logoSmall} alt="" className="forMobile" />
                <img src={logoBig} alt="" className="forDesktop" />
              </div>
              <div className='text-35 font-200 leading-[1.2] hidden lg:block text-black dark:text-white'>Qpolla</div>
            </div>
          </Link>
          {showMyBunch ? <MyBunch /> : <Drp />}
          <div className='md:relative' ref={loginRef}>
            <div>
              {!isAuthenticated && (
                <div className='flex items-center'>
                  <div className='cursor-pointer'>
                    <img src={loginBlack} alt="" className="lightMode" onClick={toggleLoginPopup} />
                    <img src={loginWhite} alt="" className="darkMode" onClick={toggleLoginPopup} />
                  </div>
                </div>
              )}
              {isAuthenticated && (
                <Menu setShowMyBunch={setShowMyBunch} showMyBunch={showMyBunch} />
              )}
            </div>
            <div className='absolute left-0 right-0 md:left-auto md:right-0'>
              {showLoginPopup && (
                <MainAuth
                  showGoogle={showGoogle}
                  showSignUp={showSignUp}
                  showLogin={showLogin}
                />
              )}
              {loginPopup && (
                <Login closePopup={closePopup} />
              )}
              {signupPopup && (
                <Signup
                  showStepTwo={showStepTwo}
                />
              )}
              {googlePopup && (
                <Google />
              )}
              {stepTwoPopup && (
                <StepTwo
                  showStepThree={showStepThree}
                />
              )}
              {stepThreePopup && (
                <StepThree
                  closePopup={closePopup}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {isAuthenticated && (
        <MobileFooter />
      )}
    </header>
  );
}

export default Header;
