import React, { createContext, useState, useEffect } from 'react';
import axiosInstance from '../../../utils/axiosInstance'; // Your configured Axios instance

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [personaDetails, setPersonaDetails] = useState([]);
  const [postDetails, setPostDetails] = useState([]);
  const [postDetailsOfPersona, setPostDetailsOfPersona] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [personaId, setPersonaId] = useState(null); // State to manage personaId
  const [postId, setPostId] = useState(null);       // State to manage postId
  const [postOfPersonaId, setPostOfPersonaId] = useState(null);       // State to manage postId
  const [call, setCall] = useState(false);

  const assignColor = (self, society) => {
    if (self > 0 && society > 0) return { color: '#6DEE2B', borderColor: '#6DEE2B' }; // Intelligent
    if (self == 0 && society > 0) return { color: '#6DEE2B', borderColor: '#6DEE2B' }; // Intelligent
    if (self > 0 && society == 0) return { color: '#6DEE2B', borderColor: '#6DEE2B' }; // Intelligent

    if (self < 0 && society < 0) return { color: '#EE4B2B', borderColor: '#EE4B2B' }; // Stupid
    if (self < 0 && society >= 0) return { color: '#EEAD2B', borderColor: '#EEAD2B' }; // Helpless
    if (self >= 0 && society < 0) return { color: '#EEAD2B', borderColor: '#EEAD2B' }; // Bandit
    return { color: '#FFFFFF', borderColor: '#4169E1' };                      // Center or on axes
  };

  const fetchPersonaDetails = async (id) => {
    let prmData = {
      personaUniqueId: id,
    };

    try {
      const response = await axiosInstance.post('common/personaSelect', prmData);
      let data = response.data.map(point => {
        const data = { self: point.self, society: point.society };
        const colorDetails = assignColor(data.self, data.society);

        if (point.graph != null && point.graph != "{}") {
          let graph = JSON.parse(point.graph);
          var scatterPlotData = graph.map(point => ({
            ...point,
            ...assignColor(point.self, point.society) // Assign color based on self and society
          }));
        }

        return {
          ...point,
          data: [{
            ...data,
            ...colorDetails
          }],
          scatterPlotData: scatterPlotData
        };
      });
      setPersonaDetails(data);
    } catch (error) {
      console.error('Error fetching persona details!', error);
      setError(error);
    }
  };

  const fetchPostDetails = async (id) => {
    let prmData = {
      postUniqueId: id,
    };

    try {
      const response = await axiosInstance.post('common/postSelect', prmData);
      let data = response.data.map(point => {
        const data = { self: point.self, society: point.society };
        const colorDetails = assignColor(data.self, data.society);

        return {
          ...point,
          data: [{
            ...data,
            ...colorDetails
          }]
        };
      });
      setPostDetails(data);
    } catch (error) {
      console.error('Error fetching post details!', error);
      setError(error);
    }
  };

  const fetchPostDetailsOfPersona = async (id) => {
    let prmData = {
      personaUniqueId: id
    };

    try {
      const response = await axiosInstance.post('common/postsOfPersona', prmData);
      let data = response.data.map(point => {
        const data = { self: point.self, society: point.society };
        const colorDetails = assignColor(data.self, data.society);

        return {
          ...point,
          data: [{
            ...data,
            ...colorDetails
          }]
        };
      });
      setPostDetailsOfPersona(data);
    } catch (error) {
      console.error('Error fetching post details!', error);
      setError(error);
    }
  };

  const fetchPersonasOfUser = async () => {
    let prmData = {
    };

    try {
      const response = await axiosInstance.post('common/personasOfUser', prmData);
      let data = response.data.map(point => {
        const data = { self: point.self, society: point.society };
        const colorDetails = assignColor(data.self, data.society);

        return {
          ...point,
          data: [{
            ...data,
            ...colorDetails
          }]
        };
      });
      setPostDetailsOfPersona(data);
    } catch (error) {
      console.error('Error fetching post details!', error);
      setError(error);
    }
  };

  // Effect to fetch data whenever personaId or postId changes
  useEffect(() => {
    setLoading(true);
    if (personaId != null && personaId >= 0) {
      fetchPersonaDetails(personaId);
    }
    setLoading(false);
  }, [personaId]);

  useEffect(() => {
    setLoading(true);
    if (postId != null && postId >= 0) {
      fetchPostDetails(postId);
    }
    setLoading(false);
  }, [postId, call]);

  useEffect(() => {
    setLoading(true);
    if (postOfPersonaId != null && postOfPersonaId >= 0) {
      fetchPostDetailsOfPersona(postOfPersonaId);
    }
    setLoading(false);
  }, [postOfPersonaId, call]);

  // Context value includes state and functions to update them
  return (
    <GlobalContext.Provider value={{
      personaDetails,
      postDetails,
      postDetailsOfPersona,
      loading,
      error,
      setPersonaId, // Provide function to update personaId
      setPostId,    // Provide function to update postId
      setPostOfPersonaId,
      setCall,
    }}>
      {children}
    </GlobalContext.Provider>
  );
};
